<template>
  <div class="container-users">
    <header-content title="Usuários">
      <div class="header-slot-template">
        <div class="d-flex justify-content-between">
          <p class="subtitle">
            Cadastre as pessoas que terão acesso ao Vitrine.
          </p>
          <div class="action-buttons">
            <b-button
              @click="openModal(null)"
              variant="primary"
              class="new-user-button"
            >
              <img
                src="../assets/Icon_Plus.svg"
                alt="Adicionar Usuário"
                class="Vector-Stroke"
              />
              Novo Usuário
            </b-button>
          </div>
        </div>
      </div>
    </header-content>

    <div class="isAdmin" v-if="currentUser.admin">
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-left">
          <div class="search-wrapper">
            <input
              id="search-bar"
              v-model="searchQuery"
              placeholder="Pesquisar"
            />
            <img
              src="../assets/Icon_Search.svg"
              alt="Pesquisar"
              class="IconSearch"
            />
          </div>
        </div>
      </div>

      <div class="text-left" :key="isKey">
        <b-card v-if="loading">
          <b-skeleton animation="wave" height="20px" class="rounded-0 mb-4" />
          <b-skeleton animation="wave" height="20px" class="rounded-0"v-for="index in 6" :key="index" />
        </b-card>
        <b-card v-else>
          <b-table
            responsive
            :items="paginatedUsers"
            :fields="fields"
            :small="true"
            :key="isKey"
          >
            <template #head()="data">
              <span class="text-no-wrap">{{ data.label }}</span>
            </template>
            <template #col()="data">
              <span v-b-tooltip.hover :title="data.label">
                {{ data.label }}
              </span>
            </template>
            <template v-slot:cell(Ações)="data">
              <b-dropdown
                :id="`dropdown-${data.index}`"
                variant="transparent"
                right
                no-caret
              >
                <template #button-content>
                  <img
                    :src="require('@/assets/Three-dots-vertical.svg')"
                    alt="Ações"
                    class="three-dots-vertical"
                  />
                </template>
                <b-dropdown-item @click="openModal(data.item)"
                  >Editar</b-dropdown-item
                >

                <b-dropdown-item @click="confirmDelete(data.item)"
                  >Excluir</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <div class="pagination-container">
            <div class="pagination Paginacao">
              <span @click="changePage('prev')" class="arrow"
                ><img src="../assets/Icon_Chevron-left.svg" alt="Anterior"
              /></span>
              <span @click="changePage('next')" class="arrow"
                ><img src="../assets/Icon_Chevron-right.svg" alt="Próximo"
              /></span>
            </div>
          </div>
        </b-card>
        <b-modal
          v-model="showModalConfirmDelete"
          title="Confirmação de Exclusão"
          centered
          cancel-title="Não"
          ok-title="Sim"
          size="md"
          @ok="deleteConfirmed"
        >
          <p>Deseja realmente excluir este usuário?</p>
        </b-modal>
        <b-modal
          id="modal-forms"
          @ok="submitForm"
          :key="isKey"
          v-model="modalShow"
          :title="isEditing ? 'Editar Usuário' : 'Novo Usuário'"
          size="xl"
          centered
          cancel-title="Cancelar"
          ok-title="Concluir"
          :ok-disabled="isDisabled"
          :cancel-disabled="isDisabled"
        >
          <div class="Forms">
            <b-form class="container-fluid p-0">
              <b-row>
                <b-col cols="12" md="12" lg="4" sm="12">
                  <b-form-group label="Nome">
                    <b-form-input v-model="user.nome" required></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12" lg="4" sm="12">
                  <b-form-group label="Email" :key="isKeyError">
                    <b-form-input
                      v-model="user.email"
                      required
                      type="email"
                      :disabled="isEditing"
                      :state="nameState"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{ message }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12" lg="4" sm="12">
                  <b-form-group label="Cargo">
                    <b-form-input v-model="user.cargo" required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" lg="4" sm="12">
                  <b-form-group label="CPF">
                    <b-form-input v-model="user.cpf" @input="updateCpfValue" v-numeric-only required maxlength="14"> </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12" lg="4" sm="12">
                  <b-form-group label="Telefone">
                    <b-form-input
                      v-model="user.telefone"
                      required
                      maxlength="15"
                      @input="updatePhoneValue"
                      v-numeric-only 
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12" lg="4" sm="12">
                  <b-form-group label="Data de Nascimento">
                    <b-form-datepicker
                      v-model="user.data_nascimento"
                      required
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="user.admin"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <h5 style="font-weight: 400">usuário administrador</h5>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="notAdmin" v-else>
      <h1>Usuário sem permissão</h1>
    </div>
  </div>
</template>

<script>
import HeaderContent from "@/components/Header.vue";
import RegisterData from "@/components/signupModal/RegisterData.vue";

import axios from "axios";
import firebase from "firebase";

import Formatters from "@/utils/Formatters";

export default {
  components: { RegisterData, HeaderContent },
  comments() {
    return {
      RegisterData,
    };
  },
  data() {
    return {
      isDisabled: false,
      loading: true,
      users: [],
      user: {
        id_cliente: "",
        nome: "",
        email: "",
        cargo: "",
        cpf: "",
        telefone: "",
        data_nascimento: "",
        created_by: "",
        created_at: "",
        updated_at: "",
        admin: false,
        razao_social: "",
        cnpj: "",
        created_users: [],
        visao_usuario: "",
      },
      systemUser: { nome: "", razao_social: "" },
      modalShow: false,
      isEditing: false,
      showModalConfirmDelete: false,
      userToDelete: null,
      searchQuery: "",
      fields: [
        { key: "nome", label: "Nome", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "cargo", label: "Cargo", sortable: true },
        { key: "cpf", label: "CPF", sortable: true },
        { key: "telefone", label: "Telefone", sortable: true },
        { key: "data_nascimento", label: "Data de Nascimento", sortable: true },
        { key: "Ações", label: "Ações" },
      ],
      currentPage: 1,
      perPage: 7,
      currentUser: {},
      isKey: 0,
      master: {},
      message: "",
      isKeyError: 0,
    };
  },
  async mounted() {
    this.currentUser = await this.$session.get("client");

    if (this.currentUser) {
      this.systemUser = {
        nome: this.currentUser.nome,
        razao_social: this.currentUser.razao_social,
      };
    }
    await this.getUsers();
  },
  computed: {
    nameState() {
      return this.message.length > 1 ? false : null;
    },
    filteredUsers() {
      return this.users.filter(
        (user) =>
          user.nome.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.cargo.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.cpf.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.telefone
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          user.data_nascimento
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
    },

    paginatedUsers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.perPage);
    },
  },
  directives: {
    'numeric-only': {
      bind(el) {
        el.addEventListener('keydown', (e) => {
          if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
          }
        });
      }
    }
  },
  methods: {
    updateCpfValue(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.user.cpf = Formatters.formatCpf(valueWithOutString)
    },
    updatePhoneValue(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.user.telefone = Formatters.formatPhone(valueWithOutString)
    },
    formatDataWhenGet() {
      this.users.forEach((user) => {
        user.telefone = Formatters.formatPhone(user.telefone);
        user.cpf = Formatters.formatCpf(user.cpf);
      });
    },
    async getUsers() {
      if (this.currentUser.user_master) {
        const response = await axios.get(
          `/api/clients/vitrine_clientes/${this.currentUser.uid}`
        );
        this.master = response.data;
        this.users = response.data.created_users;
        console.log("Usuários1", this.users);
        this.loading = false;
        this.isKey++;
        this.formatDataWhenGet();
      } else {
        const response = await axios.get(
          `/api/clients/vitrine_clientes/${this.currentUser.uid_master}`
        );
        this.users = response.data.created_users;
        console.log("Usuários2", this.users);

        this.loading = false;
        this.isKey++;
        this.formatDataWhenGet();
      }
    },
    async submitForm(event) {
      event.preventDefault();
      this.isDisabled = true;

      try {
        if (this.isEditing) {
          await this.updateUser(this.user);
        } else {
          if (
            this.user.nome &&
            this.user.email &&
            this.user.cargo &&
            this.user.cpf &&
            this.user.telefone &&
            this.user.data_nascimento
          ) {
            this.user.cnpj = this.currentUser.cnpj;
            this.user.razao_social = this.currentUser.razao_social;
            this.user.created_by = this.currentUser.uid;
            this.user.uid_master = this.master.uid;
            this.user.visao_usuario = this.master.visao_usuario;
            this.user.id_cliente = this.master.id_cliente;

            this.user.cpf = this.user.cpf.replaceAll(/\D/g, "");
            this.user.telefone = this.user.telefone.replaceAll(/\D/g, "");

            const response = await axios.post("/api/clients/vitrine_clientes", {
              user: this.user,
              user_master: this.master,
            });

            await firebase.auth().sendPasswordResetEmail(this.user.email);

            this.users = response.data;

            this.clearForm();
            this.isDisabled = false;
            this.$bvModal.hide(event.target.id);
            this.modalShow = false;
            this.isKey++;

            console.log("Usuário adicionado com sucesso");
          } else {
            this.isDisabled = false;
            console.log("Preencha todos os campos");
          }
        }
      } catch (error) {
        this.isDisabled = false;
        console.log("Erro ao salvar usuário:", error);
        this.message = "Email inválido ou já cadastrado";
      }
    },
    clearForm() {
      this.user = {
        nome: "",
        email: "",
        cargo: "",
        cpf: "",
        telefone: "",
        data_nascimento: "",
      };
      this.isEditing = false;
      this.message = "";
    },
    confirmDelete(user) {
      this.userToDelete = user;
      this.showModalConfirmDelete = true;
    },
    deleteConfirmed() {
      if (this.userToDelete) {
        this.deleteUser(this.userToDelete);
        this.userToDelete = null;
        this.showModalConfirmDelete = false;
      }
    },
    cancelDelete() {
      this.userToDelete = null;
      this.showModalConfirmDelete = false;
    },
    async deleteUser(user) {
      try {
        if (user && user.uid) {
          await axios.get(`/api/clients/vitrine_clientes/delete/${user.uid}`);
          this.users = this.users.filter((u) => u.uid !== user.uid);
          console.log("Usuário excluído com sucesso");
        } else {
          console.error(
            "Erro ao excluir usuário: Objeto 'user' ou sua propriedade 'uid' é indefinido"
          );
        }
      } catch (error) {
        console.error("Erro ao excluir usuário:", error);
      }
    },
    async updateUser(user) {
      try {
        const response = await axios.put(
          `/api/clients/vitrine_clientes/${user.uid}`,
          { user }
        );
        const updatedUser = response.data;
        const index = this.users.findIndex((u) => u.uid === updatedUser.uid);
        if (index !== -1) {
          this.$set(this.users, index, updatedUser);
        }
        console.log("Usuário atualizado com sucesso");
        this.clearForm();
        this.modalShow = false;
      } catch (error) {
        console.error("Erro ao atualizar usuário:", error);
        this.clearForm();
        this.modalShow = false;
      }
    },
    changePage(page) {
      if (page === "prev") {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      } else if (page === "next") {
        if (this.currentPage < this.totalPages) {
          this.currentPage += 1;
        }
      } else {
        this.currentPage = page;
      }
    },
    openModal(userToEdit = null) {
      if (userToEdit) {
        this.user = { ...userToEdit };
        this.isEditing = true;
      } else {
        this.user = {
          id_cliente: "",
          nome: "",
          email: "",
          cargo: "",
          cpf: "",
          telefone: "",
          data_nascimento: "",
          created_by: "",
          created_at: "",
          updated_at: "",
          admin: false,
          razao_social: "",
          cnpj: "",
          created_users: [],
          visao_usuario: "",
        };
        this.isEditing = false;
      }
      this.modalShow = true;
    },
  },
};
</script>

<style scoped>
.btn-group, .btn-group-vertical .dropup, .dropright, .dropdown, .dropleft {
  position: inherit !important;
}

.users {
  max-width: 100%;
  margin: auto;
}

.text-left {
  margin-left: 0;
}

.card-body {
  max-width: calc(100% - 1rem);
  margin: 1.5rem 0.4rem !important;
  padding: 0 !important;
}

.text-no-wrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding-right: 50px;
}

.pagination .arrow {
  cursor: pointer;
  margin: 0 5px;
}

.pagination .arrow img {
  padding: 8px 10px;
  font-size: 68px;
}

.new-user-button {
  width: 161px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0 0 24px auto;
  border-radius: 6px;
  background-color: #ed7554;
}

.Vector-Stroke {
  width: 15px;
  height: 15px;
}

.addUsers {
  width: 84px;
  height: 25px;
  flex-grow: 0;
  font-family: SourceSansPro-SemiBold;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #722381;
}

.pagination span:hover {
  background-color: #f5f5f5;
}

.pagination span[disabled] {
  color: #ddd;
  cursor: not-allowed;
}

.pagination span.active {
  background-color: #007bff;
  color: #fff;
}

.btn-text {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: inherit;
  color: #ee7b5b;
  text-decoration: none;
  cursor: pointer;
}

.search-wrapper {
  position: relative;
}

.search-wrapper input {
  padding-right: 30px;
}

.search-wrapper .IconSearch {
  position: absolute;
  right: 10px;
  top: 31%;
  transform: translateY(-50%);
}

#search-bar {
  width: 200px;
  height: 40px;
  margin: 0 0 24px 0;
  padding: 9px 16px;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  background-color: #fff;
}

.subtitle {
  font-size: 18px;
}

.user-info {
  display: flex;
  justify-content: space-between;
}

.user-info p {
  margin: 0;
  font-size: 16px;
}

.user-info strong {
  margin-right: 10px;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.user-info strong {
  margin-right: 10px;
}
</style>
