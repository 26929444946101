<template>
  <div class="container">
    <div class="header-area">
      <button @click="handleGoBackSelectStep()">
        <ArrowLeftIcon />
      </button>
      <h2 v-if="updatedUserData.visao_usuario === 'fundo_investimento'">
        Sou Agente Financeiro
      </h2>
      <h2 v-if="updatedUserData.visao_usuario === 'incorporadora'">
        Sou Incorporadora
      </h2>
    </div>
    <form class="form-area" @submit.prevent="submitForm">
      <Input
        id="nome"
        nome="nome"
        label="Nome Completo"
        v-model="updatedUserData.nome"
        placeholder="Digite seu nome completo"
        :requiredMessage="isRequiredMessages.nome"
      />
      <Input
        id="email"
        nome="email"
        label="E-mail"
        v-model="updatedUserData.email"
        placeholder="Digite seu seu e-mail"
        type="email"
        :requiredMessage="isRequiredMessages.email"
      />
      <Select
        :id="'cargo'"
        :nome="'cargo'"
        :label="'Cargo'"
        :options="cargos"
        :requiredMessage="isRequiredMessages.cargo"
        :value="updatedUserData.cargo"
        @input="updateSelectedOption"
      />
      <p class="company-data-title">Dados da empresa</p>
      <Input
        id="cnpj"
        nome="cnpj"
        label="CNPJ"
        v-model="updatedUserData.cnpj"
        placeholder="Digite o CNPJ"
        :requiredMessage="isRequiredMessages.cnpj"
        @input="formatedCnpj"
      />
      <Input
        id="razao_social"
        nome="razao_social"
        label="Razão Social"
        v-model="updatedUserData.razao_social"
        placeholder="Digite a razão social"
        :requiredMessage="isRequiredMessages.razao_social"
      />
      <!-- <b-button @click="submitForm">Continuar</b-button> -->
      <b-button class="mt-3 w-100" variant="primary" v-if="loading" disabled>
        <b-spinner small type="grow"></b-spinner>
        Loading...
      </b-button>
      <b-button class="mt-3 w-100" variant="primary" v-else @click="submitForm">
        Continuar
      </b-button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import { ArrowLeftIcon } from "vue-feather-icons";
import Input from "@/components/ui/Input.vue";
import Select from "@/components/ui/Select.vue";

import Formatters from "@/utils/Formatters.js";
import Validators from "@/utils/Validators.js";

export default {
  nome: "RegisterData",
  components: {
    ArrowLeftIcon,
    Input,
    Select,
  },
  props: {
    userData: Object,
    modalProps: Object,
    successErrorModal: Object,
  },
  data() {
    return {
      user: {
        nome: "",
        email: "",
        cargo: "",
        cnpj: "",
        razao_social: "",
        updated_at: "",
        created_at: "",
        created_users: [],
        id_cliente: "",
        visao_usuario: "",
        admin: true,
        user_master: null,
      },
      updatedUserData: this.userData,
      updateModalProps: this.modalProps,
      updateSuccessErrorModal: this.successErrorModal,
      cargos: [
        { label: "Analista", value: "analista" },
        { label: "Assistente", value: "assistente" },
        { label: "Coordenador", value: "coordenador" },
        { label: "C-Level", value: "c-level" },
        { label: "Diretor", value: "diretor" },
        { label: "Especialista", value: "especialista" },
        { label: "Estagiário", value: "estagiário" },
        { label: "Gerente", value: "gerente" },
        { label: "Supervisor", value: "supervisor" },
        { label: "Outro", value: "outro" },
      ],
      loading: false,
      isRequiredMessages: {
        nome: "",
        email: "",
        cargo: "",
        cnpj: "",
        razao_social: "",
      },
    };
  },
  methods: {
    handleGoBackSelectStep() {
      this.updateModalProps.step = "useTerms";
      this.updateModalProps.size = "lg";
      this.$emit("update-modal-props", this.updateModalProps);
    },
    updateSelectedOption(value) {
      this.updatedUserData.cargo = value;
    },
    validateOnSubmit() {
      Object.keys(this.isRequiredMessages).forEach((key) => {
        if (!this.updatedUserData[key]) {
          this.isRequiredMessages[key] = "Campo obrigatório";
        } else {
          this.isRequiredMessages[key] = "";
        }
      });

      if (!Validators.validateEmail(this.updatedUserData.email)) {
        this.isRequiredMessages.email = "E-mail inválido";
      }

      if (!Validators.validateCnpj(this.updatedUserData.cnpj)) {
        this.isRequiredMessages.cnpj = "CNPJ inválido";
      }

      return Object.values(this.isRequiredMessages).every(
        (value) => value === ""
      );
    },
    async submitForm() {
      if (this.validateOnSubmit()) {
        this.loading = true;
        this.user.cnpj = this.updatedUserData.cnpj;
        this.user.razao_social = this.updatedUserData.razao_social;
        this.user.email = this.updatedUserData.email;
        this.user.cargo = this.updatedUserData.cargo;
        this.user.nome = this.updatedUserData.nome;
        this.user.visao_usuario = this.updatedUserData.visao_usuario;
        this.user.created_at = new Date().toISOString();
        this.user.updated_at = new Date().toISOString();
        this.user.user_master = true;

        const uninterceptedAxiosInstance = axios.create();

        await uninterceptedAxiosInstance
          .post("/api/clients/vitrine_clientMaster", {
            user: this.user,
          })
          .then(async (response) => {
            console.log("Usuário adicionado com sucesso", response);
            await firebase.auth().sendPasswordResetEmail(this.user.email);
            this.updatedUserData.uid = response.data.uid;
            console.log("Usuário uiddd", this.updatedUserData);
            this.handleOpenModalSuccess();

            return console.log("Usuário adicionado com sucesso", response);
          })
          .catch((error) => {
            this.handleOpenModalError();
            return console.log("Erro ao adicionar usuário:", error);
          })
          .finally(() => {
            this.loading = false;
            this.handleOpenModalSuccess();
          });
      }

    },
    handleOpenModalError() {
      this.updateSuccessErrorModal = {
        title: "CNPJ ou e-mail já cadastrados!",
        message: `O CNPJ ${this.updatedUserData.cnpj} ou o e-mail ${this.updatedUserData.email} já estão cadastrados. Entre em contato com o administrador da empresa para solicitar um acesso.`,
        buttonLabel: "Fechar",
        isSuccess: false,
      };
      this.updateModalProps.step = "successError";
      this.$emit("update-modal-props", this.updateModalProps);
      this.$emit("update-success-error-modal", this.updateSuccessErrorModal);
    },
    handleOpenModalSuccess() {
      this.updateSuccessErrorModal = {
        title: "Seu cadastro foi realizado!",
        message: "Enviamos um link em seu e-mail para redefinir sua senha!",
        buttonLabel: "Continuar",
        isSuccess: true,
      };
      this.updateModalProps.step = "successError";
      this.$emit("update-modal-props", this.updateModalProps);
      this.$emit("update-success-error-modal", this.updateSuccessErrorModal);
    },
    formatedCnpj() {
      this.updatedUserData.cnpj = Formatters.formatCnpj(
        this.updatedUserData.cnpj
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 24px;
  padding: 0px 24px 32px 24px;

  h1 {
    font-size: 24px;
    font-weight: 500;
    color: $neutral-95;
  }
}

.header-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;

  > button {
    background: $white;
    border: none;
    cursor: pointer;

    padding: 4px;

    border-radius: 100%;

    transition: all 0.3s ease-out;

    &:hover {
      filter: brightness(0.85);
    }
  }

  > h2 {
    color: $neutral-95;
    font-size: 22px;
    font-weight: bold;

    margin-bottom: 0;

    vertical-align: middle;
  }
}

.form-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 22px;

  > button {
    margin-top: 32px;
  }
}

.company-data-title {
  margin-top: 32px;
  margin-bottom: 0px;

  color: $purple;

  text-transform: uppercase;

  font-size: 20px;
  font-weight: 500;
}
</style>
import { update } from "lodash";
